/* Davy - 2022 */

/* Générale */
:root {
  --color-1: #9E135A;
  --color-1-light: #b64e83;
  --color-1-very-light: #e7c4d6;
  --color-2: #d31d53;
  --color-3: #d31d53;
  --color-4: #F32C6A;
  --color-4-light: #f6618f;
  --color-4-very-light: #fccada;
  --color-black: #353535;
  --color-grey: #fcfcfc;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body,
h1,
h2,
h3,
a,
p,
fieldset {
  text-decoration: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--color-black);
}

h1,
h2,
h3 {
  font-weight: 500;
}

body {
  font-weight: 400;
  font-size: 14px;
}

a {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

textarea {
  overflow: auto;
  resize: vertical;
}

td,
th {
  border: 1px solid #d8e2ef;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f0f4f9;
}

th {
  background-color: #e2e9f3;
}

.table_responsive {
  width: 100%;
  overflow-x: auto;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.block_center {
  margin: 0 auto;
}

.display_inline_block {
  display: inline-block;
}

.flex_center_y {
  display: flex;
  align-items: center;
}

.full_width {
  width: 100%;
}

@media (min-width: 769px) {
  .display_pc {
      display: block;
  }
  .display_mobile {
      display: none;
  }
}

@media (max-width: 768px) {
  .display_mobile {
      display: block;
  }
  .display_pc {
      display: none;
  }
}