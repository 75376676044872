/* Davy - 2022 */

/* Notification */
.color_white {
    color: white;
}
.notif_success, .notif_danger, .notif_warning {
    padding: 16px;
}
.notif_success a, .notif_danger a, .notif_warning a {
    font-weight: 600;
    color: #222222;
}
.notif_success a:hover, .notif_danger a:hover, .notif_warning a:hover {
    color: #444444;
}
.notif_success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}
.notif_danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.notif_warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}