/* Davy - 2022 */

/* Theme */
.bg_color_1 {
    background-color: var(--color-1);
}

.bg_color_2 {
    background-color: var(--color-2);
}

.bg_color_3 {
    background-color: var(--color-3);
}

.bg_color_4 {
    background-color: var(--color-4);
}

.bg_color_grey {
    background-color: var(--color-grey);
}

.bg_image_1 {
    background-image: url("../images/fond.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.bg_image_2 {
    background-image: url("../images/fond-2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg_image_3 {
    background-image: url("../images/fond-3.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg_image_4 {
    background-image: url("../images/fond-4.jpg");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
}
*/

.color_1 {
    color: var(--color-1);
}

.color_2 {
    color: var(--color-2);
}

.color_3 {
    color: var(--color-3);
}

.color_4 {
    color: var(--color-4);
}

.color_white {
    color: white;
}

.input,
.select {
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    padding: 8px 16px;
}

.input_submit {
    display: inline-block;
    text-decoration: none;
    border: none;
    cursor: pointer;
    color: white;
    background: linear-gradient(45deg, var(--color-1), var(--color-4));
    font-weight: 700;
    font-size: 16px;
    padding: 8px 24px;
}

.input_submit:hover {
    background: linear-gradient(45deg, var(--color-1-light), var(--color-4-light));
}

.input[type="file"] {
    padding: 0;
    border: none;
    box-shadow: none;
}

.label {
    display: block;
    text-align: left;
    padding-bottom: 4px;
}

.bouton_trait_blanc, .bouton_trait_color, .bouton_plein_color {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}

.bouton_trait_blanc {
    color: white;
    border: 1px solid white;
}

.bouton_trait_blanc:hover {
    color: var(--color-3);
    background-color: white;
    border: 1px solid white;
}

.bouton_trait_color {
    color: var(--color-3);
    background-color: white;
    border: 1px solid var(--color-3);
}

.bouton_trait_color:hover {
    color: white;
    background-color: var(--color-3);
    border: 1px solid var(--color-3);
}

.bouton_plein_color {
    color: white;
    background-color: var(--color-3);
    border: 1px solid var(--color-3);
}

.bouton_plein_color:hover {
    color: var(--color-3);
    background-color: white;
    border: 1px solid var(--color-3);
}

.bouton_trait_color_anim, .bouton_trait_blanc_anim {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    padding: 0;
}

.bouton_trait_color_anim span, .bouton_trait_blanc_anim span {
    position: relative;
    display: inline-block;
    background-color: transparent;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 15px;
    transition: 0.2s;
}

.bouton_trait_color_anim span {
    color: var(--color-3);
    border: 1px solid var(--color-3);
}

.bouton_trait_blanc_anim span {
    color: white;
    border: 1px solid white;
}

.bouton_trait_color_anim span:hover {
    color: white;
    transition: 0.2s 0.1s;
}

.bouton_trait_blanc_anim span:hover {
    color: var(--color-3);
    transition: 0.2s 0.1s;
}

.bouton_trait_color_anim::before, .bouton_trait_blanc_anim::before {
    position: absolute;
    content: "";
    transition: 0.3s ease-out;
}

.bouton_trait_color_anim::before {
    background-color: var(--color-3);
}

.bouton_trait_blanc_anim::before {
    background-color: white;
}

.hover_slide_left::before {
    right: 0;
    width: 0%;
    height: 100%;
}

.hover_slide_left:hover::before {
    width: 100%;
}

.hover_slide_right::before {
    width: 0%;
    height: 100%;
}

.hover_slide_right:hover::before {
    width: 100%;
}

.hover_slide_down::before {
    width: 100%;
    height: 0%;
}

.hover_slide_down:hover::before {
    height: 100%;
}

.paragraphe {
    line-height: 24px;
}

.text_strong {
    font-weight: 500;
}

.trait_gauche {
    border-left: 1px solid var(--color-3);
}

.link_hover:hover {
    color: var(--color-3);
}

.menu_height {
    min-height: 50px;
}

.small_container {
    max-width: 720px;
    margin: 0 auto;
}

.box_shadow {
    box-shadow: 0px 0px 24px 0px #e9e9e9;
}

.box_shadow:hover {
    box-shadow: 0px 0px 24px 0px #dddddd;
}

@media (max-width: 768px) {
    .trait_gauche {
        border-left: none;
    }
}